import { getAppConfig } from '@icp/settings';
import { countryFlagEmoji } from './countryFlagEmoji';

export function getLngToPolyfill() {
  const allowedLanguages = getAppConfig()?.i18n?.allowedLanguages || [];
  const fallbackLng = getAppConfig()?.i18n?.fallbackLng || 'zh-CN';
  const res = new Set(allowedLanguages);
  res.add(fallbackLng);
  return Array.from(res);
}

// 简单写个 map 不用 Intl 接口，chrome 总是会加载 polyfill 语言多了 polyfill 就会很多
const lngLabelMap = {
  'zh-CN': '中文（中国）',
  'en-US': 'English (United States)',
  'ja-JP': '日本語 (日本)',
  'ko-KR': '한국어(대한민국)',
  'fr-FR': 'français (France)',
  'de-DE': 'Deutsch (Deutschland)',
  'es-ES': 'español (España)',
};

export function getLanguageOptions({ displayCountryFlagEmoji, countryEmojiPosition }) {
  return (getAppConfig()?.i18n?.allowedLanguages || []).map((lng) => {
    const displayName = lngLabelMap[lng] || lng;
    const labels = {
      left: (displayCountryFlagEmoji ? `${countryFlagEmoji(lng)} ` : '') + displayName,
      right: displayName + (displayCountryFlagEmoji ? ` ${countryFlagEmoji(lng)}` : ''),
    };
    return {
      value: lng,
      label: labels[countryEmojiPosition],
    };
  });
}

export function extractI18nProperties(obj, originalPropertyName) {
  if (!obj || typeof obj !== 'object') return {};

  const prefix = `${originalPropertyName}_i18n_`;

  const entries = Object.entries(obj)
    .filter(([key]) => key.startsWith(prefix))
    .map(([key, value]) => [key.slice(prefix.length), value]);

  return Object.fromEntries(entries);
}
