import { useMemo } from 'react';
import { filterInvalidFilterModel } from '@icp/components';
import { useDataSource } from '../../hooks';
import { useIsInDesign } from '../../FormRenderCtx';
import { transformTaskData } from './utils';

function useTaskDataSource({
  taskConfig,
  refresh,
  mapping,
  dateOptions,
  relationFilter,
  filterModel: filterModelState,
  searchText,
}) {
  const isInDesign = useIsInDesign();
  const filterModel = useMemo(() => {
    return filterInvalidFilterModel(filterModelState || []).map((item) => {
      if (item.filterType === 'set') {
        return {
          ...item,
          // TODO，这块代码和 TableElement 里有重复
          // TODO，测试下来后端 api 是根据 label 做过滤的？如果有需求可以在表格上提供参数选择是按照 value 还是 label 做过滤
          values: item.values.map((v) => {
            return typeof v === 'object' && v ? v.label : v;
          }),
        };
      }
      return item;
    });
  }, [filterModelState]);
  // 转换成平铺的数组，因为 react 的 hook 不让在 loop 里面使用
  const multiConfig = useMemo(() => {
    const arr = [];
    const flatten = (task, indent) => {
      if (!task) {
        return;
      }
      const mergedMapping = {
        ...mapping,
        ...task.mapping,
      };
      arr.push({
        dataResponseKeyPath: 'results',
        ...task,
        mapping: mergedMapping,
        dataFilters: (task.dataFilters || [])
          // 顶层数据源通过 relation 过滤就够了，子数据源有 parent 字段已经足够知道是谁的儿子
          .concat(filterModel, indent === 0 ? relationFilter : null)
          .filter(Boolean),
        searchText,
      });
      flatten(task.child);
    };

    flatten(taskConfig, 0);

    return arr;
  }, [filterModel, mapping, relationFilter, searchText, taskConfig]);

  const taskRes = useDataSource({
    skip: isInDesign || !taskConfig,
    refresh,
    multiDataSource: multiConfig,
  });

  const dataFetched = useMemo(() => {
    return (taskRes.dataFetched || [])
      .map((items, index) => {
        return transformTaskData({
          items,
          dataSourceConfig: multiConfig[index],
          dateOptions,
        });
      })
      .flatMap((x) => x);
  }, [dateOptions, multiConfig, taskRes.dataFetched]);

  return {
    ...taskRes,
    dataFetched,
  };
}

export default function useGanttDataSource({
  dataSources,
  refresh,
  mapping,
  dateOptions,
  relation,
  filterModel,
  searchText,
}) {
  const isInDesign = useIsInDesign();

  const relationFilter = useMemo(() => {
    if (!relation.colId || !relation.value) {
      return null;
    }

    return [
      {
        colId: relation.colId,
        filterType: 'text',
        type: 'equals',
        filter: relation.value,
      },
    ];
  }, [relation]);

  const linkConfig = useMemo(() => {
    return dataSources.link
      ? {
          dataResponseKeyPath: 'results',
          ...dataSources.link,
          dataFilters: (dataSources.link.dataFilters || []).concat(relationFilter).filter(Boolean),
        }
      : null;
  }, [dataSources.link, relationFilter]);
  const calendarConfig = dataSources.calendar;

  const taskRes = useTaskDataSource({
    taskConfig: dataSources.task,
    refresh,
    mapping,
    dateOptions,
    relationFilter,
    filterModel,
    searchText,
  });
  const linkRes = useDataSource({
    skip: isInDesign || !linkConfig,
    refresh,
    ...linkConfig,
  });
  const calendarRes = useDataSource({
    skip: isInDesign || !calendarConfig,
    refresh,
    ...calendarConfig,
  });

  const taskData = taskRes.dataFetched;
  const linkData = linkRes.dataFetched;
  const calendarData = calendarRes.dataFetched;

  const ganttData = useMemo(() => {
    return {
      data: taskData || [],
      links: linkData || [],
    };
  }, [linkData, taskData]);

  return {
    loading: taskRes.loading || linkRes.loading || calendarRes.loading,
    error: taskRes.error || linkRes.error || calendarRes.error,
    ganttData,
    calendarData,
  };
}
